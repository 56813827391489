import Head from "next/head";
import "styles/globals.css";
import "./../styles/nprogress.css"; //styles of nprogress
// Binding events. => this should display a loading bar
// Router.events.on("routeChangeStart", () => NProgress.start());
// Router.events.on("routeChangeComplete", () => NProgress.done());
// Router.events.on("routeChangeError", () => NProgress.done());

export default function MyApp(props: any) {
  // const router = useRouter();
  // console.log(router.route);

  // React.useEffect(() => {
  //   // Remove the server-side injected CSS.
  //   const jssStyles = document.querySelector("#jss-server-side");
  //   if (jssStyles) {
  //     jssStyles.parentElement!.removeChild(jssStyles);
  //   }
  // }, []);

  return (
    <Head>
      <title>Tax API</title>
    </Head>
  );
  {
    /* //     <MDXProvider components={mdxComponents}>
         <ThemeProvider theme={theme}>
           <CssBaseline />
           <Component {...pageProps} />
         </ThemeProvider>
       </MDXProvider>*/
  }
}

// MyApp.propTypes = {
//   Component: PropTypes.elementType.isRequired,
//   pageProps: PropTypes.object.isRequired,
// };
